import Head from 'next/head';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { StaticImageData } from 'next/image';

interface Props {
  children: React.ReactNode;
  header_title: string;
  background_image?: StaticImageData;
  headerHeight?: string | number;
}

function Main({ children, header_title, background_image, headerHeight }: Props) {
  return (
    <div style={{ margin: 'auto', minHeight: 800 }}>
      <Head>
        <title>Fosterburg Baptist Church</title>
        <link rel="icon" href="/assets/favicon.ico" />
      </Head>
      <Header title={header_title} background_image={background_image} height={headerHeight} />
      <div style={{ minHeight: 500 }}>{children}</div>
      <Footer />
    </div>
  );
}

export default Main;
