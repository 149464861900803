export const CANONICAL_DOMAIN = 'https://fosterburgbaptistchurch.com';

export const navConfig = [
  {
    name: 'About Us',
    type: 'group',
    subMenu: [
      // {
      //   name: 'Our Beliefs',
      //   type: 'link',
      //   url: '/our-beliefs',
      // },
      {
        name: 'Our Staff',
        type: 'link',
        url: '/meet-our-staff',
      },
      // {
      //   name: 'Our History',
      //   type: 'link',
      //   url: '/our-history',
      // },
    ],
  },
  {
    name: 'What to Expect',
    type: 'link',
    url: '/what-to-expect',
  },
  {
    name: 'Kids',
    type: 'link',
    url: '/kids-ministries',
  },
  {
    name: 'Service Times',
    type: 'link',
    url: '/service-times',
  },
  {
    name: 'Sermons',
    type: 'link',
    url: '/sermons',
  },
  {
    name: 'Contact Us',
    type: 'link',
    url: '/contact-us',
  },
  // {
  //   name: 'Special Events',
  //   type: 'group',
  //   subMenu: [
  //     {
  //       name: 'Vacation Bible School',
  //       type: 'link',
  //       url: '/vbs',
  //     },
  //   ],
  // },
];
export const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
